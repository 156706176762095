<template>
  <v-container>
    <v-row>
      <v-col>
        <h5 class="grey-text">
          <router-link class="grey-text" :to="{ name: 'Home' }"
            >Home</router-link
          >
          >
          <router-link :to="{ name: 'Blog' }">Blog</router-link>
          > Double Play Series Shortstop
        </h5>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="4">
        <v-img src="/images/DPGreen5.png" />
      </v-col>

      <v-col cols="12" sm="8" class="iframecontainer">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/MNeIz9802N8"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {}
</script>

<style scoped>
.v-application a {
  color: grey;
}
.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
